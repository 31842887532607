import './App.css';
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Context/AuthContext';
import NavbarP from './components/Navbar/NavbarP';
import Footer from './components/Footer/Footer';
import Spinner from './components/Spinner/Spinner.tsx';
import ProtectedRoute from './components/ProtectedRoute.js';

const Main = lazy(() => import('./components/Views/Main'));
const Login = lazy(() => import('./components/Login/Login'));
const SignUp = lazy(() => import('./components/Login/SignUp'));
const Create = lazy(() => import('./components/Trip/Create/Create'));
const EditTrip = lazy(() => import('./components/Views/EditTrip.jsx'));
const Trip = lazy(() => import('./components/Views/Trip.jsx')); // Asegúrate de importar Trip desde el lugar correcto
const Terms = lazy(() => import('./components/Views/Tyc'));
const CreatedTravel = lazy(() => import('./components/Views/CreatedTravel'));
const Requestss = lazy(() => import('./components/Views/Requestss'));

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <NavbarP />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Spinner />}>
                <Main />
              </Suspense>
            }
            index
          />
          <Route
            path="/login"
            element={
              <Suspense fallback={<Spinner />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/SignUp"
            element={
              <Suspense fallback={<Spinner />}>
                <SignUp />
              </Suspense>
            }
          />
          <Route
            path="/Create"
            element={
              <Suspense fallback={<Spinner />}>
                <ProtectedRoute/>
                <Create />
              </Suspense>
            }
          />
          <Route
            path="/trip/:uuid"
            element={
              <Suspense fallback={<Spinner />}>
                <ProtectedRoute/>
                <Trip />
              </Suspense>
            }
          />
          <Route
            path="/editTrip/:uuid"
            element={
              <Suspense fallback={<Spinner />}>
                <ProtectedRoute/>
                <EditTrip />
              </Suspense>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <Suspense fallback={<Spinner />}>
                <Terms />
              </Suspense>
            }
          />
          <Route
            path="/created-travel"
            element={
              <Suspense fallback={<Spinner />}> 
                <ProtectedRoute/>
                <CreatedTravel />
              </Suspense>
            }
          />
            <Route
            path="/Requests"
            element={
              <Suspense fallback={<Spinner/>}>
                <ProtectedRoute/>
                <Requestss />
              </Suspense>
            }
          />
        </Routes>
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
