import React from "react";
import "./Footer.scss";
import { ImFacebook } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import img1 from "../Assets/Recurso 12.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-column">
            <div className="logoDiv">
              <div className="footerLogo">
                <a href="#" className="logo">
                  <img src={img1} alt="logoimg" />
                </a>
              </div>
            </div>
          </div>

          <div className="footer-column">
            <div className="socials flex">
              <a href="">
                <ImFacebook className="icon" />
              </a>
              <a href="">
                <BsTwitter className="icon" />
              </a>
              <a href="https://www.tiktok.com/@viajatearg">
                <FaTiktok className="icon" />
              </a>
              <a href="https://www.instagram.com/viajatearg">
                <AiFillInstagram className="icon" />
              </a>
            </div>

            <div className="footerLinks">
              <li>
                <a href="https://www.viajate.com.ar">
                  © 2024 - www.viajate.com.ar <br>
                  </br> Todos derechos reservados.
                </a>

              </li>
            </div>
          </div>

          <div className="footer-column">
            <div className="footerLinks">
              <li>
                <a href="#">Soporte</a>
              </li>
              <li>
                <a href="#">Políticas de Privacidad</a>
              </li>
              <li>
                <NavLink
                  to="/terms-and-conditions" className="navLink"
                >
                  Términos y condiciones
                </NavLink>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
