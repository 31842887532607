// src/components/NavbarP.js
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TbGridDots } from "react-icons/tb";
import img1 from "../Assets/logo en blanco11.png";
import { useAuth } from '../Context/AuthContext';
import "../Navbar/Navbar.scss";
import { BsList } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import './ProfileCard.css'; // Asegúrate de importar el CSS de la card de perfil

function NavbarP() {
  const [active, setActive] = useState("navBar");
  const { user, logout } = useAuth();
  const [showProfileCard, setShowProfileCard] = useState(false);

  const profileCardRef = useRef();

  const handleUserClick = () => {
    setShowProfileCard(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (profileCardRef.current && !profileCardRef.current.contains(event.target)) {
      setShowProfileCard(false);
    }
  };

  useEffect(() => {
    if (showProfileCard) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showProfileCard]);

  const showNav = () => {
    setActive('navBar activeNavBar');
  };

  const removeNav = () => {
    setActive('navBar');
  };

  const [transparent, setTransparent] = useState('header');
  const addBg = () => {
    if (window.scrollY > 10) {
      setTransparent('header activeHeader');
    } else {
      setTransparent('header');
    }
  };

  window.addEventListener('scroll', addBg);

  return (
    <section className="navBarSection">
      <div className={transparent}>
        <div className="logoDiv">
          <NavLink to="/" className="logo">
            <img src={img1} alt="logoimg" />
          </NavLink>
        </div>
        <div className={active}>
          <ul className="navList flex">
            {user && (
              <li className="navItem">
                <NavLink to="/create" className="navLink">
                  Crear viaje
                </NavLink>
              </li>
            )}
           
            <div className="headerBtns flex">
              {user ? (
                <>
                  <button className="btnS signUp" onClick={handleUserClick}>
                    <span><FaUserCircle /></span>
                    <span className="username">{user.nombre}</span>
                    <span><BsList /></span>
                  </button>
                  {showProfileCard && (
                    <div ref={profileCardRef} className="profile-card-container">
                      <div className="profile-card">
                        <div className="profile-header">
                          <div className="profile-avatar"></div>
                          <div className="profile-info">
                            <div className="profile-title">Perfil viajero</div>
                            <div className="profile-greeting">¡Hola, {user.nombre}!</div>
                            <div className="profile-link">
                              <div className="profile-link-text">Ir a mi perfil viajero</div>
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c02c6e19a2596c6044fcb67f00ad4fb054ad4d3d664509b8f63f7e73e31b50d3?"
                                className="profile-link-img"
                                alt="Perfil"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="profile-menu">
                        <div className="menu-item">
        <Link to="/created-travel">Mis viajes</Link>
      </div>
                          {/* <div className="menu-item">Favoritos</div> */}
                          <div className="menu-item"><Link to="/Requests">Mis solicitudes</Link></div>
                          {/* <div className="menu-item">Alertas</div> */}
                          {/* <div className="menu-item">Notificaciones</div> */}
                          <div className="menu-item logout" onClick={logout}>Cerrar sesión</div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <button className="btn loginBtn">
                    <NavLink to="/login" className="login">
                      Ingresá a tu cuenta
                    </NavLink>
                  </button>
                  <button className="btnS signUp">
                    <NavLink to="/signup" className="signUp">
                      Regístrate
                    </NavLink>
                  </button>
                </>
              )}
            </div>
          </ul>
          <div onClick={removeNav} className="closeNavBar">
            <IoMdCloseCircleOutline className="icon" />
          </div>
        </div>
        <div onClick={showNav} className="toggleNavBar">
          <TbGridDots />
        </div>
      </div>
    </section>
  );
}

export default NavbarP;
